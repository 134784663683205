.quote-section {
    position: relative;
    background: linear-gradient(to right, rgb(200, 249, 4),rgba(6, 223, 251, 0.5)); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 0px;
  }
  .quote-section-text {
    color: #000000;
    width: 80%;
    font-size: 80px;
    text-align: left;
    margin-left: 25px;
    padding-top: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }
  .hire-me-button {
    border: none;
    background-color: rgba(0, 225, 255, 0.952);
    color: black;
    font-size: 16px;
    width: 180px;
    padding-right: 0px;
    border-radius: 30px;
    font-size: large;
    cursor: pointer;
    text-align: right;
    bottom: 40px;
    right:60px;
    position: absolute;
  }
  .hire-me-button-hover:hover{
    background-color: rgb(200, 249, 4);
  }
  .hire-me-button img{
    height: 45px;
    padding-left: 20px;
    vertical-align: middle;
  }
  @media screen and (max-width: 600px) {
    .quote-section-text  {
      font-size: 36px;
      width: 90%;
      text-align: left;
      margin-left: 10px;
      padding-top: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .hire-me-button {
     margin-top: 20px;
     bottom: 15px;
     right:20px;
    }
    .quote-section {
        height: 35vh;
    }
  }
  