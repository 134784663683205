footer {
    position: relative;
    bottom: 0;
    background-color: #f8f8f8;
    padding: 10px 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
  }
  .footer-parent-section{
    float: left;
    width: 50%;
  }
  .footer-inner-section {
    float: left;
    width: 20%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .footer-inner-section-below{
    float: left;
    text-align: left;
    width: 80%;
    margin-left: 0.3%;
  }
  .footer-inner-section-below h4 {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .footer-inner-section-below ul {
    list-style: none;
    margin: 0;
    padding-left: 20px;
  }
  .footer-inner-section-below li {
    margin-bottom: 10px;
  }
  
  .footer-inner-section-below li a {
    color: #000000;
    text-decoration: none;
  }
  
  .footer-inner-section-below li a:hover {
    text-decoration: underline;
  }
  .footer-right-inner-section{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-right-inner-section h4{
    font-size: 40px;
    margin-top: 70px;
    margin-bottom: 0px;
  }
  .footer-right-inner-section h3{
    font-size: 40px;
    margin: 0;
    padding-top: 15px;
    margin-left: -47px;
  }
  .footer-right-inner-section h2{
    font-size: 40px;
    margin: 0;
    padding-top: 15px;
    margin-left: -50px;
  }
  .row::after{
    content: "";
    display: table;
    clear: both;
  }
  .footer-inner-section h4 {
    margin-bottom: 20px;
  }
  
  .footer-inner-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-inner-section li {
    margin-bottom: 10px;
  }
  
  .footer-inner-section li a {
    color: #000000;
    text-decoration: none;
  }
  
  .footer-inner-section li a:hover {
    text-decoration: underline;
  }
  .newsletter{
    width: 60%;
    max-width: 420px;
  }
  
  .footer-right-inner-section .newsletter {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .footer-right-inner-section .newsletter input[type="email"] {
    padding: 10px;
    height: 30px;
    width: 100%;
    border-radius: 25px;
    border: none;
    font-size: large;
    flex: 1;
    background-color: rgba(6, 223, 251, 0.5);
    color: #000000;
    text-align: center;
  }
  
  .footer-right-inner-section .newsletter input[type="image"] {
    display: block;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    margin-left: -50px; 
  }
  footer p{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  /* Responsive Styles */
@media (min-height: 100vh) {
    footer {
      opacity: 1;
    }
  }
  @media screen and (max-width: 600px) {
    /* For mobile view */
    .footer-parent-section{
        width: 100%;
    }
    .footer-inner-section{
        width: 35%;
    }
    .newsletter{
      width: 80%;
    }
  }