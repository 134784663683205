.strip {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 10vh; 
    background: linear-gradient(to right, rgba(6, 223, 251, 0.5), rgb(200, 249, 4)); 
  }
.inner-strip{
    display: flex;
    align-items: center;
    transform: rotate(-1.5deg);
    transform-origin: center center;
    background: black;
    height: 75%;
    width: 100.5%;
    margin-left: -1px;
}
.strip .inner-strip .inner-strip-text{
  font-size: 22px;
  margin-left: 15px;
  color: white;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}
.inner-strip-img{
  margin-left: 15px;
}
@media screen and (max-width: 600px) {
  .inner-strip{
    transform: rotate(-2.2deg);
  }
}

  