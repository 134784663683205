.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color:#fff;
    color: #000;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    z-index: 1;
  }
  
  .navbar-brand {
    font-weight: bold;
    font-size: 24px;
    margin-right: 20px;
  }
  .navbar-brand a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 24px;
    margin-right: 20px;
  }
  .navbar-menu {
    list-style: none;
    margin-left: auto;
    margin: 0;
    padding-top: 5px;
    display: flex;
    padding-right: 30px;
    justify-content: flex-end;
  }  
  .navbar-menu li {
    margin-right: 20px;
  }
  .navbar-menu a {
    color: #000;
    text-decoration: none;
    font-size: 18px;
  }
  .navbar-menu a:hover {
    text-decoration: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color:  rgba(6, 223, 251, 0.5);;
    color: #333;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
  }
  .contact-me {
    display: flex;
    align-items: center;
    margin-right: 0;
    text-decoration: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color:  rgba(6, 223, 251, 0.5);
    color: #333;
    margin-top: -10px;
    margin-left: 0;
    border-radius: 15px;
    height: 33px;
    width: 105px;
    padding-left: 10px;
    text-align: right;
    
  }
  .contact-me img{
    vertical-align: middle;
    padding-left: 12px;
    height: 30px;
  }
  .navbar-menu .contact-me:hover {
    text-decoration: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color:  rgba(6, 223, 251, 0.5);;
    color: #333;
    border-radius: 5px;
    padding: 5px 10px;
    transition: all 0.3s ease-in-out;
  }
  main{
    flex-grow: 1;
  }  
  .nav-toggle {
    display: none;
  }
  @media screen and (max-width: 600px) {
    /* For mobile view */
    .navbar-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
        padding: 10px;
    }
  
    .navbar-menu li {
        display: block;
        margin-top: 6px;
        margin-bottom: 10px;
    }
  
    .nav-toggle {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url("./resources/menu.png");
        background-size: contain;
        background-repeat: no-repeat;
        height: 30px;
        width: 30px;
        cursor: pointer;
        border: none;
        background-color: white;   
    }
    .navbar-menu.show {
        display: flex;
        text-align: center;
        flex-direction: column; 
        align-items: center;
    }
    .contact-me {
      margin-top: 2px;
    }
  }