.slide {
    flex-wrap: wrap;
    align-items: center;
  }
  .slider-row::after{
    position: relative;
    content: "";
    display: table;
    clear: both;
  }
  .slider-column {
    float: left;
    width: 50%;
    box-sizing: border-box;
  }
  .slider-row .slider-column .quote {
    font-size: 40px;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 8%;
    margin-right: 5%;
    margin-bottom: 10px;
    word-wrap: break-word;
    text-align: left;
  }
  
  .slider-row .slider-column .text {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 25px;
    margin-left: 8%;
    margin-right: 5%;
    color: dimgray;
    word-wrap: break-word;
    text-align: left;
  }
  
  .slider-button {
    border: none;
    background-color: rgba(6, 223, 251, 0.5);
    color: black;
    font-size: 16px;
    width: 180px;
    padding-right: 0px;
    border-radius: 30px;
    font-size: large;
    cursor: pointer;
    position: relative;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 8%;
  }
  .slider-button-hover:hover{
    background-color: rgb(200, 249, 4);
  }
  .slider-button img{
    height: 45px;
    padding-left: 20px;
    vertical-align: middle;
  }
  .image-container {
    height: 55vh; 
    position: relative;
  }
  
  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .responsive-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
  
  
  @media screen and (max-width: 600px) {
    .slider-column {
      width: 100%;
      text-align: center;
    }
    .slider-row .slider-column .quote {
      font-size: 33px;
    }
    .slider-button {
      margin-left: 0px;
    }
    .image-container {
      height: 40vh; 
      position: relative;
    }
  }