.slider2-button {
    border: none;
    background-color: rgba(6, 223, 251, 0.5);
    color: black;
    font-size: 16px;
    width: 180px;
    padding-right: 0px;
    border-radius: 30px;
    font-size: large;
    cursor: pointer;
    position: relative;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 8%;
  }
  .slider2-button-hover:hover{
    background-color: rgb(200, 249, 4);
  }
  .slider2-button img{
    height: 45px;
    padding-left: 20px;
    vertical-align: middle;
  }