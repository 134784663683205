.worked-with-section {
    margin-top: 20px;
    padding-bottom: 50px;
  }
  .worked-with-text{
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: bold;
}

.worked-with-logos {
    display: flex;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.5s, transform 3s;
    justify-content: center;
  }
  .worked-with-logos.animation {
    opacity: 1;
    transform: translateX(0);
  }
  .logo-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .logo-container {
    width: 140px;
    height: 140px;
    margin: 10px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 50%;
    transition: opacity 0.5s, transform 0.5s;
  }
  .logo-container:hover{
    padding: 35px;
  }
  .company-logo {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
  @media screen and (max-width: 600px) {
    .worked-with-text{
        margin-left: 20px;
    }
    .logo-container {
        padding: 10px;
    }
    .logo-container:hover{
        padding: 15px;
      }
    .worked-with-logos {
        transform: translateX(-100%);
        transition: opacity 0.5s, transform 1.5s;
      }
  }
