.slider-container {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  .slide {
    flex: 1;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: transform 0.5s ease-in-out;
  }
  .slide h1 {
    font-size: 24px;
  }
  .slide p {
    font-size: 16px;
  }
  .slide.active{
    width: 100%;
    display: block;
    transform: translateX(0%);
  }
  .slide.next {
    transform: translateX(100%);
  }
  
  .slide.previous {
    transform: translateX(-100%);
  }
  