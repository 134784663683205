.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .about-heading {
    margin-top: 40px;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
  
  .about-paragraphs {
    margin-top: 17px;
    font-size: 22px;
    color: dimgray;
    text-align: justify;
  }
  .expand-button {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .expand-button span {
    margin-right: 5px;
    color: dimgray;
  }
  
  .expand-button svg {
    width: 16px;
    height: 16px;
  }
  .below-about-strip{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    font-size: 22px;
    color: dimgray ;
    height: 200px;
  }
  .left-design{
    display: flex;
    flex: 0.25;
  }
  .shape-left {
    width: 80%; 
    height: 100%; 
    background-color: rgb(219, 253, 228);
    clip-path: polygon(0 0, 60% 0, 100% 100%, 0 100%); 
  }
  .middle-follow-social-media{
    flex: 0.5;
    align-items: center;
    text-align: center;
  }
  .middle-follow-social-media i{
    padding: 20px 10px;
    margin: 10px;
    transition: background-color 0.8s ease; 
  }
  .right-design{
    display: flex;
    flex: 0.25;
  }
  .shape-right {
    width: 100%; 
    height: 100%; 
    background-color: rgb(188, 238, 255);
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 0 100%); 
  }
.redirect-edu-cert{
    border: none;
    background: linear-gradient(to right, rgb(219, 253, 228), rgb(188, 238, 255));
    color: dimgray;
    font-size: 24px;
    width: 50%;
    padding-right: 0px;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50px;
}
  @media screen and (max-width: 600px) {
    .about-container {
        width: 80%;
    }
    .about-paragraphs {
        font-size: 18px;
        margin-top: 0px;
    }
    .about-heading {
        font-size: 24px;
      }
      .expand-button {
        display: flex;
      }
      .redirect-edu-cert{
        height: 40px;
        font-size: 18px;
        width: 100%;
      }
      .below-about-strip{
        font-size: 18px;
        height: 130px;
      }
      .shape-left {
        width: 100%; 
      }
  }
  