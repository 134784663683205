.latest-projects-text{
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: bold;
}
.projects-section {
    display: grid;
    opacity: 0;
    transform: translateX(50%);
    transition: opacity 0.5s, transform 3s;
  }
.projects-section.animation {
    opacity: 1;
    transform: translateX(0);
}
  .project-card {
    width: 350px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: 20px;
    transition: all 0.3s ease-in-out;
  }
  .project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .project-card:hover {
    transform: scale(1.05);
  }
  
  .project-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .project-card h3 {
    margin-bottom: 5px;
  }
  
  .project-card p {
    margin-bottom: 10px;
  }
  
  .project-description {
    display: none;
  }
  
  .project-card:hover .project-description {
    display: block;
  }
@media screen and (max-width: 600px) {
    .latest-projects-text{
        margin-left: 20px;
    }
    .project-card {
      width: 100%;
      justify-content: center;
    }      
    .project-list {
        width: 100%;
    }
    .projects-section {
        width: 100%;
    }
    .projects-section {
        transform: translateX(100%);
        transition: opacity 0.5s, transform 1.5s;
      }
}