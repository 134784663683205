
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popup-content {
    position: relative;
    background-color: #fff;
    text-align: left;
    padding: 20px;
    width: 60%;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .popup h2 {
    margin-top: 0;
  }
  
  form label {
    display: block;
    margin-bottom: 5px;
  }
  
  form input,
  form textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
  }

  .contact-form {
    max-width: 100%; 
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input,
  textarea,
  .reason-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .other-reason-select {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  /* Additional styling for visual appeal */
  .contact-form {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 8px;
  }
  
  label {
    font-size: 14px;
  }
  
  input,
  textarea {
    font-size: 14px;
  }
  
  .submit-button {
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    .popup-content {
      width: 88%;
    }
  }
  
  